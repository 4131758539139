/* src/pages/SendCoupons.css */

.admin-panel {
    background-color: #f5f5f5; /* Light brown background */
    color: #333; /* Dark text color */
    padding: 20px;
    max-width: 600px;
    margin: auto;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-left: 300px;
    
   
  }
  
  h1 {
    color: #6d4c41; /* Brown color */
    text-align: center;
  }
  
  .send-coupons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  
  }
  
  .send-coupons-container label {
    margin-bottom: 15px;
  }
  
  .send-coupons-container select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .send-coupons-container button {
    padding: 10px;
    background-color: #6d4c41; /* Brown color */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .send-coupons-container button:disabled {
    background-color: #a1887f; /* Lighter brown color */
  }
  
  .message {
    margin-top: 20px;
    color: #d32f2f; /* Error color */
  }
  
  @media (max-width:1080px){
    .admin-panel {
      margin-left: 0;
      padding: 50px;
    }
  }