* {
  font-family: "Ubuntu", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --blue: #723207;
  --white: #fff;
  --gray: #f5f5f5;
  --black1: #222;
  --black2: #999;
}

body {
  min-height: 100vh;
  overflow-x: hidden;
}

.container {
  position: relative;
  width: 300px;
  z-index: 999;
}

.container_main .hamburger {
  position: relative;
}

/* =============== Navigation ================ */
.navigation {
  position: fixed;
  width: 300px;
  height: 100%;
  background: #723207;
  border-left: 10px solid #723207;
  transition: 0.5s;
  overflow: hidden;
}
.navigation.active {
  width: 80px;
}

.navigation ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.navigation ul li {
  position: relative;
  width: 100%;
  list-style: none;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.navigation ul li:hover,
.navigation ul li.hovered {
  background-color: var(--white);
}

.navigation ul li:nth-child(1) {
  margin-bottom: 40px;
  pointer-events: none;
}

.navigation ul li a {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: var(--white);
}
.navigation ul li:hover a,
.navigation ul li.hovered a {
  color: var(--blue);
}

.navigation ul li a .icon {
  position: relative;
  display: block;
  min-width: 60px;
  height: 60px;
  line-height: 61px;
  text-align: center;
  
}
.navigation .clogo >img{
  width: 150px;
  position: relative;
  left: 20%;
  
  
}

.navigation ul li a .icon ion-icon {
  font-size: 1.75rem;
}

.navigation ul li a .title {
  position: relative;
  display: block;
  padding: 0 10px;
  height: 60px;
  line-height: 60px;
  text-align: start;
  white-space: nowrap;
}

/* --------- curve outside ---------- */
.navigation ul li:hover a::before,
.navigation ul li.hovered a::before {
  content: "";
  position: absolute;
  right: 0;
  top: -50px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 35px 35px 0 10px var(--white);
  pointer-events: none;
}
.navigation ul li:hover a::after,
.navigation ul li.hovered a::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -50px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 35px -35px 0 10px var(--white);
  pointer-events: none;
}

/* =============== Hamburger Menu ================ */
.hamburger {
  display: none;
  position: fixed;
  top: 10px;
  left: 10px;
  font-size: 2rem;
  cursor: pointer;
  color: var(--blue);
  z-index: 1000;
  height: fit-content;
}

.container.open .navigation {
  left: 0;
}
@media (max-width: 1080px) {
 
.sidebar_main {
  left: -50px;
}

.container {
  width: 100%;
  left: -500px;
}

.navigation {
  width: 300px;
  left: -350px;
}

.container.open .navigation {
  left: 0;
}

.hamburger {
  display: block;
  color: rgb(42, 2, 2);
}

}

@media (max-width: 768px) {
  .sidebar_main {
    left: -50px;
  }

  .container {
    width: 100%;
    left: -500px;
  }

  .navigation {
    width: 300px;
    left: -350px;
  }

  .container.open .navigation {
    left: 0;
  }

  .hamburger {
    display: block;
    color: rgb(42, 2, 2);
  }
}
