/* Base styles */
* {
  font-family: "Ubuntu", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #723207;
  --primary-color-hover: #A17659;
  --background-color: #f0f0f0;
  --error-color: red;
}

/* body {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
 
  background-size: cover;
} */

.containfill {
   background: url('../assets/bgdry.jpg.jpg') ;
   background-size: cover;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  height: 100vh;
 
}

.img_div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.img_div img {
  width: 40%;
  /* max-width: 200px; */
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  background-color: var(--background-color);
  padding: 40px 20px;
  border-radius: 30px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25), 0 6px 6px rgba(0, 0, 0, 0.22);
  border: 4px solid transparent;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
}

.login-container:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.login-container::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(60deg, #ff6b6b, #f3ff56, #50e3c2, #723207);
  background-size: 300% 300%;
  z-index: -1;
  transition: opacity 0.4s;
  animation: gradientBorder 3s linear infinite;
}

.login-container::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  background-color: var(--background-color);
  border-radius: 30px;
  z-index: -1;
}

.login-container h2 {
  margin-bottom: 20px;
  text-align: center;
}

.login-container form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.login-container input {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  border-radius: 20px;
  border: none;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}

.login-container button {
  padding: 10px;
  font-size: 16px;
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  width: 100%;
  margin-top: 10px;
}

.login-container button:hover {
  background-color: var(--primary-color-hover);
}

.error {
  color: var(--error-color);
  margin-bottom: 10px;
  text-align: center;
}

/* Keyframes for the gradient animation */
@keyframes gradientBorder {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .img_div img {
    width: 50%;
    max-width: 150px;
  }

  .login-container {
    padding: 30px 15px;
  }

  .login-container h2 {
    font-size: 1.5rem;
  }

  .login-container input {
    font-size: 14px;
  }

  .login-container button {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .img_div img {
    width: 70%;
    max-width: 120px;
  }

  .login-container {
    padding: 20px 10px;
  }

  .login-container h2 {
    font-size: 1.25rem;
  }

  .login-container input {
    font-size: 12px;
  }

  .login-container button {
    font-size: 12px;
  }
}
