.dashboard {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 250px; /* Adjust this value based on the width of your sidebar */
  background-color: #f0f0f0; /* Example color */
  /* Add other sidebar styles */
}

.main1-content {
  flex: 1;
  padding: 20px;
  background-color: #DCC0A9;
  height: fit-content;
  overflow-x: auto; /* Ensure content does not overflow horizontally */
}

/* Ensure the main content width adjusts to available space */
@media (min-width: 1080px) {
  .dashboard {
    flex-direction: row;
  }
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-box {
  position: relative;
  display: flex;
  align-items: center;
  width: 500px;
}

.search-box input {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  outline: none;
}

.search-icon {
  position: absolute;
  right: 10px;
  color: #723207;
  cursor: pointer;
}

.dashboard-content {
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.purchased-products {
  margin-bottom: 20px;
}

.product-item {
  background-color: #FFFFFF;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.product-list {
  margin-top: 40px;
}

.product-list h3 {
  margin-bottom: 20px;
}

.product-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.product-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: 10px;
  border-radius: 8px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.product-item span {
  font-size: 16px;
}

.product-item.delivered {
  background-color: #DCC0A9;
  color: #723207;
}

.product-item.pending {
  background-color: #FFF5E1;
  color: #723207;
}

.product-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logout-btn {
  background-color: #723207;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup-box {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.popup-box button {
  margin: 10px;
  padding: 10px;
  cursor: pointer;
}

.product-status .product-info {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .search-box {
    width: fit-content;
  }
 
  .dashboard {
    width: 100%;
  }
}

