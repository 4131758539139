.create-offer-page {
  display: flex;
  flex-direction: column;
}

.main-content {
  margin-left: 300px;
  padding: 20px;
  width: calc(100% - 300px); /* Adjusted to ensure proper width */
}

.button-container {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  margin-top: 31px;
  flex-wrap: wrap;
}

.create-offer-btn, .create-coupon-btn {
  display: flex;
  align-items: center;
  background-color: #723207;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.create-offer-btn svg, .create-coupon-btn svg {
  margin-right: 5px;
}

.create-offer-btn:hover, .create-coupon-btn:hover {
  background-color: #DCC0A9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.offers-list, .coupons-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  justify-content: center; /* Center the items */
}

.offer-card, .coupon-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 calc(33.333% - 20px); /* 3 cards per row, adjusted for spacing */
  max-width: calc(33.333% - 20px); /* Ensure max-width is the same */
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}
.offer-card:hover, .coupon-card{
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.offer-card img {
  width: 20%;
  border-radius: 5px;
}

.offer-details, .coupon-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.offer-details button, .coupon-details button {
  background-color: #723207;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 5px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.offer-details button svg, .coupon-details button svg {
  margin-right: 5px;
}

.offer-details button:hover, .coupon-details button:hover {
  background-color: #DCC0A9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-box {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  position: relative;
  animation: slideDown 0.3s ease;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
}
.delte{
  color: white;
  background-color: brown;
  outline: none;
  border: none;
  padding: 10px;
  border-radius: 8px;
  font-weight: bolder;

}
.delte:hover{
  color: brown;
  background-color: #DCC0A9;

}

form h2 {
  margin-bottom: 20px;
}

form label {
  display: block;
  margin-bottom: 5px;
}

form input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

form button {
  background-color: #723207;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

form button[type="button"] {
  background-color: #ddd;
  color: black;
}

form button:hover {
  background-color: #DCC0A9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@keyframes slideDown {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-box {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  width: 80%;
  max-width: 600px;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.popup-overlay img{
  width: 20%;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .offer-card, .coupon-card {
    flex: 1 1 calc(50% - 20px); /* 2 cards per row */
    max-width: calc(50% - 20px);
  }
}
@media (max-width: 1080px) {
  .main-content {
    margin-left: 0px;
    padding: 20px;
    width: 100%;
}}

@media (max-width: 768px) {
  .main-content {
    margin-left: 0;
    width: 100%; /* Ensure the main content takes full width */
  }

  .offer-card, .coupon-card {
    flex: 1 1 calc(100% - 20px); /* 1 card per row */
    max-width: calc(100% - 20px);
  }

  .button-container {
    flex-direction: column;
  }

  .create-offer-btn, .create-coupon-btn {
    width: 100%;
    justify-content: center;
  }
}
